.table {
  border: 1px solid #a4b7c1;
}
.fullHeight {
  height: 100%;
}
.marginBottom {
  margin-bottom: 1em !important;
}
.smallMarginBottom {
  margin-bottom: 4px !important;
}
.marginRight {
  margin-right: 1em !important;
}
.marginTop {
  margin-top: 30px !important;
}
.detailsIcon {
  vertical-align: bottom;
  margin-left: 0.5em;
}
.smallDots {
  width: 10px !important;
  height: 10px !important;
}
.smallLoadingComponent {
  margin: 0 !important;
  width: 100% !important;
}
.paginationStyle {
  margin-right: 1em !important;
  width: 6em !important;
}
.noBorderTop {
  border-top: none !important;
}
.profileButton {
  display: block !important;
  color: rgb(21, 27, 30) !important;
  padding-left: 0 !important;
}
.profilePicture {
  width: 50% !important;
  height: 100% !important;
  border-radius: 100% !important;
}
.pictureBox {
  display: flex;
  justify-content: center;
  margin-bottom: 1em !important;
}
.aside-menu {
  padding: 1em !important;
}
.responsiveBox {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;

  list-style-type: none;
  margin: 0;
  padding: 0;
}
.leadInfoResponsive {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;
  -webkit-column-width: 150px;
  -moz-column-width: 150px;
  column-width: 150px;

  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1em;
}
.topInfo {
  -webkit-column-fill: auto;
  -moz-column-fill: auto;
  column-fill: auto;
  height: 150px;
}
.leadBox {
  border: 1px solid #a4b7c1;
  padding: 1.5em;
}
.fullWidth {
  width: 100% !important;
}

.filterList {
  margin-left: 0 !important;
  position: static !important;
}

.filterLabel {
  margin-left: 0.5em !important;
}
label:hover {
  cursor: pointer !important;
}
.modal-body {
  overflow-y: auto !important;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4 !important;
}
.sidebar .nav-dropdown.open .nav-dropdown-items {
  background-color: white !important;
}
.sidebar .nav-dropdown-toggle {
  background-color: #e4e5e6 !important;
}
.sidebar {
  color: #29363d !important;
}
.sidebar:hover,
.nav-link:hover {
  color: #29363d !important;
}
.react-bs-table tr:hover {
  background-color: #e4e5e6;
  cursor: pointer;
}
.tableTd {
  max-width: 50px !important;
}
.noTrStyle:hover {
  cursor: default;
  background-color: inherit;
}
.form-control[readonly] {
  cursor: default;
}
.tableCheckBoxes {
  position: initial !important;
  margin-left: 0 !important;
  cursor: pointer !important;
}
.headerButton {
  margin-top: 0 !important;
}
.resultModal {
  font-size: 1.5em !important;
}
.linkButton {
  padding-left: 0 !important;
  font-size: 1.2em !important;
}
.unSaved {
  border-color: #00b5ff !important;
}
.errorLead {
  color: red;
  /*
        Fargeforslag:
        blå: #0074A3, evt #007AAC
        Rød: #A10702
        Blåfargen er to-tre shader mørkere enn den i nettbureau-logoen.
    */
}
.noBorder {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}
.nav-tabs .nav-link.active {
  border: none !important;
  border-bottom: 3px solid #00b5ff !important;
}
.nav-tabs .nav-link:hover {
  border: none !important;
  border-bottom: 3px solid #00b5ff !important;
}
.nav-tabs .nav-link {
  border: none !important;
  border-bottom: 3px solid transparent !important;
  color: #151b1e !important;
}
.nav-tabs {
  color: #151b1e !important;
}
.disabledRules {
  opacity: 0.5;
}
.debugLead {
  color: #6610f2;
}
.calendarBox {
  position: absolute;
  z-index: 100;
  border: 1px solid rgb(128, 128, 128);
}

.dropdownMenu {
  padding: 1em !important;
  width: 100%;
}

.card {
  margin-top: 1.5em !important;
}
.tab-content {
  margin-bottom: 1em;
}

.modal-dialog {
  width: 70% !important;
}

.backgroundForPopup {
  width: 100%;
  background-color: pink;
  position: fixed;
  top: 0;
  z-index: 3000;
  left: 0;
  display: flex;
}
.htmlScheme {
  width: 100%;
}

.card-body a {
  color: #0059b3 !important;
}

a.btn {
  color: #fff !important;
}

.centerAlign {
  align-items: center !important;
}

.greyBorder {
  background-color: #e4e5e6;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 1300px) {
  .responsiveBox {
    -moz-column-count: 3;
    -moz-column-gap: 15px;
    -webkit-column-count: 3;
    -webkit-column-gap: 15px;
    column-count: 3;
    column-gap: 15px;
  }
}
@media only screen and (max-width: 720px) {
  .responsiveBox {
    -moz-column-count: 2;
    -moz-column-gap: 10px;
    -webkit-column-count: 2;
    -webkit-column-gap: 10px;
    column-count: 2;
    column-gap: 10px;
  }
}
@media only screen and (max-width: 860px) {
  .leadInfoResponsive {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    -webkit-column-width: 150px;
    -moz-column-width: 150px;
    column-width: 150px;

    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .form .float-right {
    float: left !important;
  }
}

.container {
  height: 80px;
  width: 65px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.container::after {
  color: #00b5ff;
  font-weight: 400;
  position: absolute;
  bottom: -80px;
  left: -10px;
}
.box {
  position: relative;
  height: 80px;
  width: 65px;
  animation: box 5s infinite linear;
}

.border {
  background: #00b5ff;
  position: absolute;
}

.border.one {
  height: 0px;
  top: 0;
  left: 0;
  animation: border-one 5s infinite linear;
}

.border.two {
  top: 0;
  right: 0;
  height: 100%;
  width: 0px;
  animation: border-two 5s infinite linear;
}

.border.three {
  bottom: 0;
  right: 0;
  height: 0px;
  width: 100%;
  animation: border-three 5s infinite linear;
}

.border.four {
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0px;
  animation: border-four 5s infinite linear;
}

.line {
  height: 4px;
  background: #00b5ff;
  position: absolute;
  width: 0%;
  left: 25%;
}

.line.one {
  top: 25%;
  width: 0%;
  animation: line-one 5s infinite linear;
}

.line.two {
  top: 45%;
  animation: line-two 5s infinite linear;
}

.line.three {
  top: 65%;
  animation: line-three 5s infinite linear;
}

@keyframes border-one {
  0% {
    width: 0;
  }
  10% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes border-two {
  0% {
    height: 0;
  }
  10% {
    height: 0%;
  }
  20% {
    height: 100%;
  }
  100% {
    height: 100%;
  }
}

@keyframes border-three {
  0% {
    width: 0;
  }
  20% {
    width: 0%;
  }
  30% {
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes border-four {
  0% {
    height: 0;
  }
  30% {
    height: 0%;
  }
  40% {
    height: 100%;
  }
  100% {
    height: 100%;
  }
}

@keyframes line-one {
  0% {
    left: 25%;
    width: 0;
  }
  40% {
    left: 25%;
    width: 0%;
  }
  43% {
    left: 25%;
    width: 50%;
  }
  52% {
    left: 25%;
    width: 50%;
  }
  54% {
    left: 25%;
    width: 0%;
  }
  55% {
    right: 25%;
    left: auto;
  }
  63% {
    width: 10%;
    right: 25%;
    left: auto;
  }
  100% {
    width: 10%;
    right: 25%;
    left: auto;
  }
}

@keyframes line-two {
  0% {
    width: 0;
  }
  42% {
    width: 0%;
  }
  45% {
    width: 50%;
  }
  53% {
    width: 50%;
  }
  54% {
    width: 0%;
  }
  60% {
    width: 50%;
  }
  100% {
    width: 50%;
  }
}

@keyframes line-three {
  0% {
    width: 0;
  }
  45% {
    width: 0%;
  }
  48% {
    width: 50%;
  }
  51% {
    width: 50%;
  }
  52% {
    width: 0%;
  }
  100% {
    width: 0%;
  }
}

@keyframes box {
  0% {
    opacity: 1;
    margin-left: 0px;
    height: 80px;
    width: 65px;
  }
  55% {
    margin-left: 0px;
    height: 80px;
    width: 65px;
  }
  60% {
    margin-left: 0px;
    height: 35px;
    width: 80px;
  }
  74% {
    msthin-left: 0;
  }
  80% {
    margin-left: -80px;
    opacity: 1;
  }
  90% {
    height: 35px;
    width: 80px;
    margin-left: 80px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.MuiTableCell-head {
  font-weight: 700 !important;
}

.MuiCard-root {
  overflow: visible !important;
}

.MuiChip-root {
  cursor: inherit !important;
}

* {
  outline: none !important;
}

[name] {
  scroll-margin: 32px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
